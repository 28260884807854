import { v5 } from 'uuid';

const namespace = process.env.GATSBY_UUID_V5_NAMESPACE ?? '';

class UuidV5 {

  static generate(value: string): string {
    if (!namespace) throw new Error('UUID v5 namespace is not defined');
    return v5(value, namespace);
  }

}

export { UuidV5 };