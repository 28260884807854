import { UuidV5 } from 'models/services/uuid-v5';
import { calcCostRate } from 'models/services/formula';
import { DishCategoryCollection, DishCategoryArrayField } from 'models/entities/dish-category';
import { IngredientEditorCollection, IngredientEditor, MaxDepth, IngredientCollection } from 'models/entities/ingredient';
import { IngredientItem, InHouseIngredientItemEditor } from 'models/entities/ingredient-item';
import { StringField, NumberField, BooleanField } from 'models/value-objects/editor-field';

import { DishItem, DishItemDataToIdentify, DishItemDataToRevise } from './index';
import { CreateGql, UpdateGql, DeleteGql } from './gql';
import { DishItemTransaction } from './transaction';

type Data = {
  original: DishItem;
  categories: DishCategoryCollection;
};

type DataToInit = {
  canDelete?: boolean;
};

type DataToApply = {
  category?: DishCategoryArrayField;
  name?: StringField;
  posItemId?: StringField;
  ingredients?: IngredientEditorCollection;
  price?: NumberField;
  isIngredientItem?: BooleanField;
  ingredientItem?: InHouseIngredientItemEditor;
};

type DataToValidate = {
  category?: DishCategoryArrayField;
  name?: StringField;
  posItemId?: StringField;
  ingredients?: IngredientEditorCollection;
  price?: NumberField;
  isIngredientItem?: BooleanField;
  ingredientItem?: InHouseIngredientItemEditor;
};

type DataToCreate = {
  categoryId: string;
  order: string;
  name: string;
  price: number;
  posItemId?: string;
};

type DataToUpdate = {
  categoryId?: string;
  order?: string;
  name?: string;
  price?: number;
  posItemId?: string;
};

type DataToRebuild = {
  ingredients?: IngredientCollection;
  ingredientItemId?: string;
};

class DishItemEditor {

  readonly original: DishItem;
  readonly categories: DishCategoryCollection;
  readonly category: DishCategoryArrayField;
  readonly name: StringField;
  readonly posItemId: StringField;
  readonly ingredients: IngredientEditorCollection;
  readonly price: NumberField;
  readonly cost?: number;
  readonly costRate?: number;
  readonly isIngredientItem: BooleanField;
  readonly ingredientItem?: InHouseIngredientItemEditor;
  readonly depthLimit: number;
  readonly canDelete: boolean;
  readonly dirty: boolean;
  readonly ok: boolean;

  constructor(data: Data) {
    this.original = data.original;
    this.categories = data.categories;
    this.category = new DishCategoryArrayField(this.categories.documents, this.original.category);
    this.name = new StringField({ string: this.original.name });
    this.posItemId = new StringField({ string: this.original.posItemId, required: false });
    this.ingredients = new IngredientEditorCollection({ original: this.original.ingredients });
    this.price = new NumberField({ number: this.original.price });
    this.cost = this.original.cost;
    this.costRate = this.original.costRate;
    this.isIngredientItem = new BooleanField({ boolean: !!this.original.ingredientItemId });
    this.depthLimit = this.getDepthLimit(this.isIngredientItem);
    this.canDelete = !this.original.ingredientItemId;
    this.dirty = false;
    this.ok = this.validate();
  }

  init(data: DataToInit): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ...data });
  }

  connect(ingredientItem: InHouseIngredientItemEditor): this {
    return Object.assign(Object.create(this.constructor.prototype), { ...this, ingredientItem });
  }

  apply(data: DataToApply): this {
    const props = { ...this, ...data };
    const cost = props.ingredients.cost;
    const costRate = calcCostRate(cost, props.price.number ?? 0);
    const ingredientItem = props.ingredientItem ? props.ingredientItem.apply({ name: props.name }) : undefined;
    const depthLimit = this.getDepthLimit(props.isIngredientItem);
    const dirty = true;
    const ok = this.validate(data);
    return Object.assign(Object.create(this.constructor.prototype), { ...props, cost, costRate, ingredientItem, depthLimit, dirty, ok });
  }

  applyIngredient(editor: IngredientEditor): this {
    return this.apply({ ingredients: this.ingredients.replace(editor) });
  }

  moveIngredient(editor: IngredientEditor, index: number): this {
    return this.apply({ ingredients: this.ingredients.move(editor, index) });
  }

  removeIngredient(editor: IngredientEditor): this {
    return this.apply({ ingredients: this.ingredients.remove(editor) });
  }

  addIngredientItem(item: IngredientItem): this {
    return this.apply({ ingredients: this.ingredients.addItem(item) });
  }

  insertIngredientItem(item: IngredientItem, index: number): this {
    return this.apply({ ingredients: this.ingredients.insertItem(item, index) });
  }

  disconnect(): this {
    if (!this.ingredientItem) throw new Error('ingredientItem is undefined');
    return this.apply({ ingredientItem: this.ingredientItem.remove() });
  }

  reconnect(): this {
    if (!this.ingredientItem) throw new Error('ingredientItem is undefined');
    return this.apply({ ingredientItem: this.ingredientItem.restore() });
  }

  validate(data: DataToValidate = {}): boolean {
    const props = { ...this, ...data };
    if (!props.category.ok) return false;
    if (!props.name.ok) return false;
    if (!props.posItemId.ok) return false;
    if (!props.ingredients.ok) return false;
    if (!props.price.ok) return false;
    if (props.ingredientItem && !props.ingredientItem.ok) return false;
    return true;
  }

  async save(): Promise<DishItemEditor> {
    if (!this.validate()) throw new Error('invalid object');
    const that = this.adjustPosItemId();
    if (that.ingredients.dirty || that.ingredientItem?.dirty) return await that.saveWithTransaction();
    return that.original.id ? await that.update() : await that.create();
  }

  private async create(): Promise<DishItemEditor> {
    const gql = await new CreateGql().fetch({ menuCategoryId: this.original.menuCategoryId, input: this.getDataToCreate() });
    if (!gql.document) throw new Error('invalid document');
    return this.identify(gql.document);
  }

  private async update(): Promise<DishItemEditor> {
    if (!this.original.id) throw new Error('invalid id');
    const gql = await new UpdateGql().fetch({ id: this.original.id, input: this.getDataToUpdate() });
    if (!gql.document) throw new Error('invalid document');
    return this.revise(gql.document);
  }

  private async saveWithTransaction(): Promise<DishItemEditor> {
    const transaction = await new DishItemTransaction({ dishItem: this }).save();
    if (!transaction.result) throw new Error('invalid result');
    return transaction.result.dishItem;
  }

  getDataToCreate(): DataToCreate {
    const categoryId = this.category.object!.id;
    const order = this.original.order.toString();
    const name = this.name.string!;
    const price = this.price.number!;
    const posItemId = this.posItemId.string || undefined;
    return { categoryId, order, name, price, posItemId };
  }

  getDataToUpdate(): DataToUpdate {
    const categoryId = this.category.dirt?.id;
    const name = this.name.dirt;
    const price = this.price.dirt;
    const posItemId = this.posItemId.dirt;
    return { categoryId, name, price, posItemId };
  }

  identify(data: DishItemDataToIdentify, dataToRebuild?: DataToRebuild): DishItemEditor {
    const original = this.rebuild(dataToRebuild).identify(data);
    return new DishItemEditor({ original, categories: this.categories });
  }

  revise(data: DishItemDataToRevise = {}, dataToRebuild?: DataToRebuild): DishItemEditor {
    const original = this.rebuild(dataToRebuild).revise(data);
    return new DishItemEditor({ original, categories: this.categories });
  }

  rebuild(data: DataToRebuild = {}): DishItem {
    if (!this.validate()) throw new Error('invalid object');
    const category = this.category.object!;
    const name = this.name.string!;
    const posItemId = this.posItemId.string || '';
    const ingredients = data.ingredients ?? this.ingredients.rebuild();
    const price = this.price.number!;
    const ingredientItemId = this.isIngredientItem.boolean ? data.ingredientItemId ?? this.ingredientItem?.original.id ?? '' : '';
    return this.original.apply({ category, name, posItemId, ingredients, price, ingredientItemId });
  }

  async delete(): Promise<this> {
    if (!this.canDelete) throw new Error('cannot delete');
    if (!this.original.id) throw new Error('invalid id');
    if (this.ingredients.editors.length || this.ingredientItem) return await this.deleteWithTransaction();
    const gql = await new DeleteGql().fetch({ id: this.original.id });
    if (!gql.document) throw new Error('invalid document');
    return this;
  }

  private async deleteWithTransaction(): Promise<this> {
    const transaction = await new DishItemTransaction({ dishItem: this }).delete();
    if (!transaction.result) throw new Error('invalid result');
    return this;
  }

  private getDepthLimit(isIngredientItem: BooleanField): number {
    return !isIngredientItem.boolean ? MaxDepth : MaxDepth - 1;
  }

  private adjustPosItemId(): this {
    const posItemId = this.original.id ? this.updatePosItemId() : this.createPosItemId();
    return this.apply({ posItemId });
  }

  private createPosItemId(): StringField {
    if (!this.validate()) throw new Error('invalid object');
    if (!this.posItemId.string) return this.posItemId.change(UuidV5.generate(this.name.string!));
    return this.posItemId;
  }

  private updatePosItemId(): StringField {
    if (!this.validate()) throw new Error('invalid object');
    const oldDefaultId = UuidV5.generate(this.original.name);
    const newDefaultId = this.category.object!.original.offMenu ? '' : UuidV5.generate(this.name.string!);
    switch (true) {
      case !this.name.dirty && !this.posItemId.dirty: return this.posItemId;
      case !this.name.dirty && this.posItemId.dirty: return this.posItemId.dirt ? this.posItemId : this.posItemId.change(newDefaultId);
      case this.name.dirty && !this.posItemId.dirty: return this.posItemId.string !== oldDefaultId ? this.posItemId : this.posItemId.change(newDefaultId);
      case this.name.dirty && this.posItemId.dirty: return this.posItemId.dirt ? this.posItemId : this.posItemId.change(newDefaultId);
    }
    return this.posItemId;
  }

}

export { DishItemEditor };
export type {
  DataToApply as DishItemEditorDataToApply,
  DataToCreate as DishItemEditorDataToCreate,
  DataToUpdate as DishItemEditorDataToUpdate,
};